<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs
      :routes="[{ name: 'Inicio' }, { name: 'Coberturas' }, { name: 'Listado de coberturas' }]"
    />
    <v-card>      
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Listado de Coberturas</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'CreateCobertura' }" color="primary">
         <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
          Agregar Cobertura
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap style="margin-bottom:25px;">
          <v-flex sm6>
            <v-text-field
              v-model="search"
              box
              append-icon="search"
              label="Buscar"              
              clearable
              hide-details
            />
          </v-flex>
          <v-flex sm6>
            <v-btn
              :loading="loading4"
              :disabled="loading4"
              color="primary"
              large
              @click="searchData"
              style="width:160px;"              
            >
            <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
            </svg>
              Buscar
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn color="error" large @click="clearSearch">
            <v-icon dark style="margin-right:10px;">
                  delete
                </v-icon>
              Limpiar
            </v-btn>
          </v-flex>
        </v-layout>
        <v-data-table
          :headers="[
            { text: 'Courier', value: 'courier.nombre' },
            { text: 'Ciudad Origen', value: 'ciudadOrigen.nombre' },
            { text: 'Ciudad Destino', value: 'ciudadDestino.nombre' },
            { text: 'Cálculo de Valor', value: 'calculo_valor' },
            { text: 'Precio', value: 'precio' },
            { text: 'Min envío gratis', value: 'monto_minimo_gratis' },
            { text: 'Estado', value: 'estado' },
            { text: 'Acciones' }
          ]"
          :items="coberturas"
          hide-actions
          class="elevation-1"
        >
          <tr slot="items" slot-scope="props">
            <td class="px-3">
              {{ props.item.courier.nombre }}
            </td>
            <td class="px-3">
              {{ props.item.ciudadOrigen.nombre }}
            </td>
            <td class="px-3">
              {{ props.item.ciudadDestino.nombre }}
            </td>
            <td class="px-3">
              {{ props.item.calculo_valor }}
            </td>
            <td v-if="props.item.calculo_valor != 'API'" class="px-3">
              {{ props.item.precio }}
            </td>
            <td v-else class="px-3">
              -
            </td>
            <td class="px-3">
              {{ props.item.monto_minimo_gratis }}
            </td>
            <td class="px-3">
              <v-chip v-if="props.item.estado === true" small color="primary" text-color="white">
                ACTIVO
              </v-chip>
              <v-chip v-else-if="props.item.estado === false" small>
                INACTIVO
              </v-chip>
            </td>
            <td class="px-3">
              <v-btn
                class="ma-0"
                :to="{ name: 'EditCobertura', params: { id: props.item.id } }"
                small
                icon
                flat
                color="info"
              >
                <v-icon small>
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                class="ma-0"
                @click="openModalDeleteCobertura(props.item)"
                small
                icon
                flat
                color="error"
              >
                <v-icon small>
                  delete
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </v-data-table>

        <div class="text-xs-center pt-2 md4 lg4">
          <v-pagination
            @input="paginationChangeHandler"
            v-model="pagination.pag1"
            :length="pages"
            :total-visible="5"
            circle
          ></v-pagination>
        </div>
      </v-container>
    </v-card>
    <ModalDelete @onChange="getData" />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;
export default {
  metaInfo() {
    return { title: "Listado de coberturas" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/coberturas/ModalDelete")
  },
  data() {
    return {
      pagination: { totalItems: 0, rowsPerPage: 15, pag1: 1 },
      coberturas: [],
      pages: "",
      search: "",
      loading4: false
    };
  },

  computed: {
    ...mapState({
      coberturas: state => state.coberturas.coberturas,
      loadingCoberturas: state => state.coberturas.loadingCoberturas
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions({
      getCoberturas: "coberturas/getCoberturas",
      replaceShowModalDeleteCobertura: "coberturas/replaceShowModalDeleteCobertura",
      replaceCurrentCobertura: "coberturas/replaceCurrentCobertura"
    }),
    paginationChangeHandler(pageNumber) {
      this.pagination.pag1 = pageNumber;
      this.getData();
    },
    clearSearch() {
      this.getData();
      this.search = "";
    },
    searchData() {
      this.loading4 = true;
      this.pagination.pag1 = 1;
      axios.get(`${HOST}/coberturas?search=${this.search}`).then(response => {
        this.loading4 = false;
        this.coberturas = response.data.data;
        this.totalPassengers = response.data.meta.total;
        this.numberOfPages = response.data.meta.last_page;
        this.pages = response.data.meta.last_page;
        this.pagination.pag1 = response.data.meta.current_page;
      });
    },
    getData() {
      let url = `${HOST}/coberturas?page=${this.pagination.pag1}`;
      axios.get(url).then(response => {
        this.pages = response.data.meta.last_page;
        this.coberturas = response.data.data;
        this.loading = false;
        this.pagination.totalItems = response.data.meta.total;
        this.pagination.rowsPerPage = response.data.meta.per_page;
        this.pagination.pag1 = response.data.meta.current_page;
        this.pages = response.data.meta.last_page;
      });
    },
    openModalDeleteCobertura(cobertura) {
      this.replaceCurrentCobertura({ cobertura });
      this.replaceShowModalDeleteCobertura({ status: true });
    }
  }
};
</script>
